/* You can add global styles to this file, and also import other style files */
.ant-collapse > .daniel.ant-collapse-item > .ant-collapse-header {
  font-weight: bold;
}

.bordes {
  border-radius: 30px;
}
.ant-modal-content {
  border-radius: 10px;
}
.ant-modal-header {
  border-radius: 10px;
}
.ant-btn {
  border-radius: 10px;
}
.borderElement {
  border-bottom: none;
}
.borderElement:not(:last-child) {
  border-bottom: 1px solid rgb(196, 193, 193);
}
.ant-popover-inner {
  border-radius: 10px;
}

.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  display: none;
}
